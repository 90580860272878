.menu-title {
  font-size: 6vh;
}

@media only screen and (min-width: 100px) and (max-width: 768px) {
  .menu-title {
    font-size: 2.5vh;
  }
}
@media only screen and (max-width: 768px) {
  .card {
    width: 90%;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 255, 0.5);
    animation: pulse-glow 3s infinite;
  }
}
@media only screen and (min-width: 769px) {
  .card {
    width: 40%;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(1, 1, 33, 0.5);
    animation: pulse-glow 3s infinite;
  }
}

@keyframes pulse-glow {
  0% {
    box-shadow: 0 0 20px rgba(3, 3, 3, 0.5);
    border-color: aqua;
  }
  25% {
    box-shadow: 0 0 30px rgba(17, 17, 18, 0.7);
    border-color: rgb(102, 38, 38);
  }
  50% {
    box-shadow: 0 0 30px rgba(17, 17, 18, 0.7);
    border-color: orange;
  }
  75% {
    box-shadow: 0 0 30px rgba(17, 17, 18, 0.7);
    border-color: greenyellow;
  }
  100% {
    box-shadow: 0 0 20px rgba(9, 9, 9, 0.5);
    border-color: orangered;
  }
}
