.event-table {
  border-collapse: collapse;
  width: 100%;
  margin: 0 auto;
}

.event-table th,
.event-table td {
  border: 2px solid #e6e6e6; /* Light gray for border */
  padding: 12px;
  text-align: center;
}

.event-table thead {
  background-color: #0064c7; /* Dark blue for header background */
  color: white;
  font-weight: bold;
}

.event-table tbody tr:nth-child(even) {
  background-color: #f0f0f0; /* Light gray for even rows */
}

.event-table tbody tr {
  background-color: #e6e6e6; /* Slightly darker gray for odd rows */
}

.table-row {
  background-color: #f9f9f9; /* Background color for the row */
  border-bottom: 1px solid #e0e0e0; /* Border color for the row */
  transition: background-color 0.2s; /* Smooth hover effect */
  cursor: pointer; /* Show a pointer cursor on hover */
}

.table-row:hover {
  background-color: #e0e0e0; /* Hover background color */
}
