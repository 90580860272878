@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,800;0,900;1,800&display=swap");

$color_governor_bay_approx: #a492ff;
$color_bright_turquoise_approx: #7237e7;
$white: #fff;
$color_alabaster_approx: #022945;
$color_ship_gray_approx: #383d41;
$color_science_blue_approx: #0062cc;
$color_abbey_approx: #000000;

.container-wrapper {
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 768px) {
  .text-gradient {
    background: linear-gradient(to bottom, #c401cb, #dd927c);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

@media only screen and (max-width: 768px) {
  .text-gradient {
    background: linear-gradient(to bottom, #520404, #6d0101);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

@media only screen and (min-width: 768px) {
  .text-gradientt {
    background: linear-gradient(to bottom, #cbe6ff, #ee9a81);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

@media only screen and (max-width: 768px) {
  .text-gradientt {
    background: linear-gradient(to bottom, #1a1713, #000000);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.content-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.user-registration {
  color: aliceblue;
  background: linear-gradient(to right, #b87777, #a8c2de);
  margin-bottom: 5%;
}

@media (max-width: 500px) {
  .register {
    background: linear-gradient(
      to right,
      $color_governor_bay_approx 0,
      $color_bright_turquoise_approx 100%
    );
    margin-top: 15%;
    padding: 3%;
    overflow: hidden;
    font-size: 2.5vh;

    .btnRegister {
      font-size: large;
    }

    .headddd {
      font-size: 3vh;
    }

    input {
      font-size: x-small;
    }

    .register-heading {
      text-align: center;
      margin-top: 0%;
      margin-bottom: -22%;
      color: aliceblue;

      @media (max-width: 500px) {
        font-size: 0.8rem;
      }
      @media (max-width: 991px) {
        font-size: 1.5rem;
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .register {
    background: linear-gradient(
      to right,
      $color_governor_bay_approx 0,
      $color_bright_turquoise_approx 100%
    );
    margin-top: 20%;
    padding: 3%;
    overflow: hidden;
    font-size: 3vh;

    .register-left {
      margin-top: -40%;
    }

    .btnRegister {
      font-size: large;
    }

    input {
      font-size: small;
    }

    .register-heading {
      text-align: center;
      margin-top: 5%;
      margin-bottom: -22%;
      color: aliceblue;
      font-size: 1.5rem;
    }

    .btnRegister {
      float: right;
      margin-top: 5%;
      border: 2px solid #fd00a0;
      border-radius: 1.5rem;
      padding: 1.5%;
      background: linear-gradient(#3e0e37, #5e0239);
      color: $white;
      font-weight: 300;
      width: 70%;
      font-size: medium;
      cursor: pointer;
    }
    .btnRegister:hover {
      float: right;
      margin-top: 5%;
      border: 2px solid #e3dce5;
      border-radius: 1.5rem;
      padding: 1.5%;
      background: linear-gradient(#000000, #004187);
      color: $white;
      font-weight: 400;
      width: 70%;
      cursor: pointer;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .register {
    background: linear-gradient(
      to right,
      $color_governor_bay_approx 0,
      $color_bright_turquoise_approx 100%
    );
    margin-top: 20%;
    padding: 3%;
    overflow: hidden;
    font-size: 3vh;

    .register-left {
      margin-top: -40%;
    }

    .btnRegister {
      font-size: large;
    }

    input {
      font-size: small;
    }

    .register-heading {
      text-align: center;
      margin-top: 5%;
      margin-bottom: -21%;
      color: aliceblue;
      font-size: 1.5rem;
    }
    .btnRegister {
      float: right;
      margin-top: 5%;
      border: 2px solid #fd00a0;
      border-radius: 1.5rem;
      padding: 1.5%;
      background: linear-gradient(#3e0e37, #5e0239);
      color: $white;
      font-weight: 300;
      width: 70%;
      font-size: medium;
      cursor: pointer;
    }
    .btnRegister:hover {
      float: right;
      margin-top: 5%;
      border: 2px solid #e3dce5;
      border-radius: 1.5rem;
      padding: 1.5%;
      background: linear-gradient(#000000, #004187);
      color: $white;
      font-weight: 400;
      width: 70%;
      cursor: pointer;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .register {
    background: linear-gradient(
      to right,
      $color_governor_bay_approx 0,
      $color_bright_turquoise_approx 100%
    );
    margin-top: 20%;
    padding: 3%;
    overflow: hidden;
    font-size: 3vh;

    .btnRegister {
      font-size: large;
    }

    input {
      font-size: small;
    }

    .register-heading {
      text-align: center;
      margin-top: 5%;
      margin-bottom: -25%;
      color: aliceblue;
      font-size: 1.5rem;
    }

    .btnRegister {
      float: right;
      margin-top: 5%;
      border: 2px solid #fd00a0;
      border-radius: 1.5rem;
      padding: 1.5%;
      background: linear-gradient(#3e0e37, #5e0239);
      color: $white;
      font-weight: 300;
      width: 70%;
      font-size: medium;
      cursor: pointer;
    }
    .btnRegister:hover {
      float: right;
      margin-top: 5%;
      border: 2px solid #e3dce5;
      border-radius: 1.5rem;
      padding: 1.5%;
      background: linear-gradient(#000000, #004187);
      color: $white;
      font-weight: 400;
      width: 70%;
      cursor: pointer;
    }
  }
}

@media only screen and (min-width: 500px) and (max-width: 768px) {
  .register {
    background: linear-gradient(
      to right,
      $color_governor_bay_approx 0,
      $color_bright_turquoise_approx 100%
    );
    margin-top: 20%;
    padding: 3%;
    overflow: hidden;
    font-size: 3vh;

    .text-gradient {
      color: aliceblue;
    }

    .btnRegister {
      font-size: large;
    }
    .row {
      margin-left: -1%;
    }
    input {
      font-size: small;
    }

    .register-heading {
      text-align: center;
      margin-top: 2%;
      margin-bottom: -22%;
      color: aliceblue;
      font-size: 1.5rem;
    }
  }
}

.register {
  color: aliceblue;
  background: linear-gradient(
    to right,
    $color_governor_bay_approx 0,
    $color_bright_turquoise_approx 100%
  );
  background-size: cover;
  margin-top: -5%;
  margin-bottom: 5%;
  min-width: 100%;
  min-height: 100%;
  margin-left: -2%;
  padding: 5%;

  .register-form {
    padding: 10%;
    padding-bottom: 3%;
    margin-top: 8%;

    @media (max-width: 991px) {
      margin-top: 16%;
    }
    @media (min-width: 992px) {
      margin-top: 13%;
    }

    @media (max-width: 768px) {
      margin-top: 10%;
    }
  }
}

.register-right {
  margin-left: -1%;
  background: $color_alabaster_approx;
  background-size: cover;
  border-top-left-radius: 5% 10%;
  border-bottom-left-radius: 5% 10%;
  border-top-right-radius: 5% 10%;
  border-bottom-right-radius: 5% 10%;
  border: 4px solid rgb(29, 255, 236);
}

@media (max-width: 768px) {
  .register-right {
    margin-left: -1%;
    margin-top: 2%;
    margin-bottom: 0%;
    background: transparent;
    background-size: cover;
    border: none;
  }
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
}

.btnRegister {
  float: center;
  margin-top: 10%;
  border: 2px solid #fd00a0;
  border-radius: 1.5rem;
  padding: 1.5%;
  background: linear-gradient(#3e0e37, #5e0239);
  color: $white;
  font-weight: 300;
  width: 100%;
  font-size: medium;
  cursor: pointer;
}
.btnRegister:hover {
  float: right;
  margin-top: 10%;
  border: 2px solid #e3dce5;
  border-radius: 1.5rem;
  padding: 1.5%;
  background: linear-gradient(#000000, #004187);
  color: $white;
  font-weight: 400;
  width: 100%;
  cursor: pointer;
}

.form-control {
  border: 2.5px solid #000000;
}

.form-control:hover {
  border: 2.5px solid #0bb0c6;
  background: linear-gradient(#bffef2, #b8cee7);
}

.profile-image-container {
  position: relative;
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 50%;
  margin: 0 auto;
}

.loading-spinner {
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-top: 3px solid #d40071;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.no-profile-image {
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
  background-color: #f1f1f1;
  color: #888;
}

.form-control {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
}

.success-message {
  background-color: #36b37e;
  color: #fff;
  padding: 10px 20px;
  text-align: center;
  border-radius: 5px;
  margin-top: 10px;
}
