$color_governor_bay_approx: #a492ff;
$color_bright_turquoise_approx: #7237e7;
$white: #fff;
$color_alabaster_approx: #022945;
$color_ship_gray_approx: #383d41;
$color_science_blue_approx: #0062cc;
$color_abbey_approx: #000000;

.container-wrapper {
  display: flex;
  flex-direction: column;
}

.content-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.user-registration {
  color: aliceblue;
  background: linear-gradient(to right, #b87777, #a8c2de);
  margin-bottom: 5%;
}

.buttonn {
  background-color: #ffffff00;
  color: #fff;
  width: 8.5em;
  height: 2.9em;
  border: #3654ff 0.2em solid;
  border-radius: 11px;
  text-align: right;
  transition: all 0.6s ease;
}

.buttonn:hover {
  background-color: #3654ff;
  cursor: pointer;
}

.buttonn svg {
  width: 1.6em;
  margin: -0.2em 0.8em 1em;
  position: absolute;
  display: flex;
  transition: all 0.6s ease;
}

.buttonn:hover svg {
  transform: translateX(5px);
}

.text {
  margin: 0 1.5em;
}

@media (max-width: 500px) {
  .registerr {
    margin-top: 15%;
    padding: 3%;
    overflow: hidden;

    .btnRegister {
      font-size: large;
    }

    .headddd {
      font-size: 3vh;
    }

    input {
      font-size: x-small;
    }

    .register-heading {
      text-align: center;
      margin-top: 0%;
      margin-bottom: -22%;
      color: aliceblue;

      @media (max-width: 500px) {
        font-size: 0.8rem;
      }
      @media (max-width: 991px) {
        font-size: 1.5rem;
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .registerr {
    margin-top: 20%;
    padding: 3%;
    overflow: hidden;

    .register-left {
      margin-top: -40%;
    }

    .btnRegister {
      font-size: large;
    }

    input {
      font-size: small;
    }

    .register-heading {
      text-align: center;
      margin-top: 5%;
      margin-bottom: -22%;
      color: aliceblue;
      font-size: 1.5rem;
    }

    .btnRegister {
      float: right;
      margin-top: 5%;
      border: 2px solid #fd00a0;
      border-radius: 1.5rem;
      padding: 1.5%;
      background: linear-gradient(#3e0e37, #5e0239);
      color: $white;
      font-weight: 300;
      width: 70%;
      font-size: medium;
      cursor: pointer;
    }
    .btnRegister:hover {
      float: right;
      margin-top: 5%;
      border: 2px solid #e3dce5;
      border-radius: 1.5rem;
      padding: 1.5%;
      background: linear-gradient(#000000, #004187);
      color: $white;
      font-weight: 400;
      width: 70%;
      cursor: pointer;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .registerr {
    margin-top: 20%;
    padding: 3%;
    overflow: hidden;

    .register-left {
      margin-top: -40%;
    }

    .btnRegister {
      font-size: large;
    }

    input {
      font-size: small;
    }

    .register-heading {
      text-align: center;
      margin-top: 5%;
      margin-bottom: -21%;
      color: aliceblue;
      font-size: 1.5rem;
    }
    .btnRegister {
      float: right;
      margin-top: 5%;
      border: 2px solid #fd00a0;
      border-radius: 1.5rem;
      padding: 1.5%;
      background: linear-gradient(#3e0e37, #5e0239);
      color: $white;
      font-weight: 300;
      width: 70%;
      font-size: medium;
      cursor: pointer;
    }
    .btnRegister:hover {
      float: right;
      margin-top: 5%;
      border: 2px solid #e3dce5;
      border-radius: 1.5rem;
      padding: 1.5%;
      background: linear-gradient(#000000, #004187);
      color: $white;
      font-weight: 400;
      width: 70%;
      cursor: pointer;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .registerr {
    margin-top: 20%;
    padding: 3%;
    overflow: hidden;

    .btnRegister {
      font-size: large;
    }

    input {
      font-size: small;
    }

    .register-heading {
      text-align: center;
      margin-top: 5%;
      margin-bottom: -25%;
      color: aliceblue;
      font-size: 1.5rem;
    }

    .btnRegister {
      float: right;
      margin-top: 5%;
      border: 2px solid #fd00a0;
      border-radius: 1.5rem;
      padding: 1.5%;
      background: linear-gradient(#3e0e37, #5e0239);
      color: $white;
      font-weight: 300;
      width: 70%;
      font-size: medium;
      cursor: pointer;
    }
    .btnRegister:hover {
      float: right;
      margin-top: 5%;
      border: 2px solid #e3dce5;
      border-radius: 1.5rem;
      padding: 1.5%;
      background: linear-gradient(#000000, #004187);
      color: $white;
      font-weight: 400;
      width: 70%;
      cursor: pointer;
    }
  }
}

@media only screen and (min-width: 500px) and (max-width: 768px) {
  .registerr {
    margin-top: 20%;
    padding: 3%;
    overflow: hidden;

    .btnRegister {
      font-size: large;
    }
    .row {
      margin-left: -1%;
    }
    input {
      font-size: small;
    }

    .register-heading {
      text-align: center;
      margin-top: 2%;
      margin-bottom: -22%;
      color: aliceblue;
      font-size: 1.5rem;
    }
  }
}

.registerr {
  color: aliceblue;
  background-size: cover;
  margin-top: -5%;
  margin-bottom: 5%;
  min-width: 100%;
  min-height: 100%;
  margin-left: -2%;
  padding: 5%;

  .register-form {
    padding: 10%;
    padding-bottom: 3%;
    margin-top: 8%;

    @media (max-width: 991px) {
      margin-top: 16%;
    }
    @media (min-width: 992px) {
      margin-top: 13%;
    }

    @media (max-width: 768px) {
      margin-top: 10%;
    }
  }
}

.register-right1 {
  margin-left: -1%;
  margin-bottom: 0%;
  background: $color_alabaster_approx;
  background-size: cover;
  border-top-left-radius: 20% 30%;
  border-bottom-left-radius: 20% 30%;
  border-top-right-radius: 20% 30%;
  border-bottom-right-radius: 20% 30%;
}

@media (max-width: 768px) {
  .register-right1 {
    margin-left: -1%;
    margin-top: 2%;
    margin-bottom: 10%;
    background: transparent;
    background-size: cover;
    border-top-left-radius: 20% 30%;
    border-bottom-left-radius: 20% 30%;
    border-top-right-radius: 20% 30%;
    border-bottom-right-radius: 20% 30%;
  }
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
}

.btnRegister {
  float: center;
  margin-top: 10%;
  border: 2px solid #fd00a0;
  border-radius: 1.5rem;
  padding: 1.5%;
  background: linear-gradient(#3e0e37, #5e0239);
  color: $white;
  font-weight: 300;
  width: 100%;
  font-size: medium;
  cursor: pointer;
}
.btnRegister:hover {
  float: right;
  margin-top: 10%;
  border: 2px solid #e3dce5;
  border-radius: 1.5rem;
  padding: 1.5%;
  background: linear-gradient(#000000, #004187);
  color: $white;
  font-weight: 400;
  width: 100%;
  cursor: pointer;
}

.form-control {
  border: 2.5px solid #000000;
}

.form-control:hover {
  border: 2.5px solid #0bb0c6;
  background: linear-gradient(#bffef2, #b8cee7);
}

/* Add this CSS to your stylesheet */

/* Style the dropdown container */
select {
  padding: 10px;
  padding-right: 5px;
  border: 1px solid #0d0101;
  border-radius: 5px;
  background-color: #fff;
  color: #333;
  font-size: 16px;
  cursor: pointer;
  outline: none;
}

/* Style the individual options */
.custom-option {
  padding: 10px;
  background-color: #f5f5f5;
  color: #333;
  font-size: 16px;
  transition: background-color 0.2s ease;
}

/* Style the selected option */
.custom-option:checked {
  background-color: #007bff;
  color: #fff;
}
