@media only screen and (max-width: 575px) {
  .cycle img {
    width: auto;
    height: auto;
  }
  .text p {
    font-size: 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cycle img {
    width: auto;
    height: auto;
  }
  .text p {
    font-size: 12px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cycle img {
    width: auto;
    height: auto;
  }
  .text p {
    font-size: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cycle img {
    width: auto;
    height: auto;
  }
  .text p {
    font-size: auto;
  }
}

@media only screen and (min-width: 1200px) {
  .cycle img {
    width: auto;
    height: auto;
  }
  .text p {
    font-size: auto;
  }
}
