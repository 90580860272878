@media (min-width: 550px) {
    .profile-button {
      font-size: 2.5vh;
    }
  }
  
  .tilt-neon {
    font-family: "Tilt neon";
    font-weight: 400;
  }
  
  @media (max-width: 550px) {
    .profile-button {
      font-size: 2.5vh;
    }
  }
  .hamburger-icon {
    display: none;
    background: none;
    border: none;
    color: #36cdff;
    font-size: 24px;
    cursor: pointer;
    position: absolute;
    right: 20px;
    z-index: 9999;
  }
  
  .mobile-menu {
    display: none;
    background: #09214a;
    color: #fff;
    padding: 20px;
  }
  
  .mobile-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .mobile-menu ul li {
    margin-bottom: 10px;
  }
  
  /* Responsive Styles */
  
  @media (max-width: 767px) {
    .hamburger-icon {
      display: block;
    }
  
    .flex-grow ul {
      display: none;
    }
  
    .mobile-menu {
      display: block;
    }
  }