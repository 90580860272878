@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,800;0,900;1,800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-image {
  opacity: 0.9; 
}

.tilt-neon {
  font-family: "Tilt neon";
  font-weight: 400;
}

.app-wrapper {
  width: 100%;
}

@keyframes logoAnimation {
  0% {
    transform: scale(0) ;
  }

  /* 25% {
    transform: rotate();
  } */
  100% {
    transform: scale(1) ;
  }
}

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  #preloader img {
    animation-name: logoAnimation;
    animation-duration: 2s;
    animation-fill-mode:forwards;
    height: 20%;
    width: 70%;
  }
}

@media (min-width: 768px) {
#preloader img {
  animation-name: logoAnimation;
  animation-duration: 2s;
  animation-fill-mode:forwards;
  height: 60%;
  width: 60%;
}
}

#preloader:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #011217;
  animation-name: backgroundAnimation;
  animation-duration: 4s;
  animation-iteration-count: infinite;
}

#preloader-none{
  display:none;
}


::-webkit-scrollbar {
  width: 1vh;
}

::-webkit-scrollbar-track {
  background: #370606;
}

::-webkit-scrollbar-thumb {
  background: rgba(1, 255, 179, 0.959);
  border-radius: 12px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(1, 217, 255, 0.911);
  border-radius: 12px;
}

.container {
  position: absolute;
  margin-top: 10%;
  font-family: 'Montserrat';
  font-weight: 600;
  left: 2%;
  font-size: 200%;
  color: rgb(255, 255, 255);
}

.cont{
  margin-top: 18%;
}

.container1 {
  position: absolute;
  top: 110%;
  left: 6%;
  font-size: 200%;
  color: black;
  font-family: 'Montserrat';
}  

@media only screen and (min-width: 1200px){
  

  .logo-image {
    margin: 0 auto;
    max-width: 80%;
    max-height: 80%;
  }

  .hov {
    font-size: 150%;
    background: -webkit-linear-gradient( #efeded, #65e0ff);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   font-weight: 600;
   }
   
 
   .hov:hover{
    font-size: 150%;
    background: -webkit-linear-gradient( #dfdcdc, #0bfa93);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   font-weight: 600;
 }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  
  .logo-image {
    margin: 0 auto;
    max-width: 80%;
    max-height: 80%;
  }


  .hov {
      font-size: 135%;
      background: -webkit-linear-gradient( #efeded, #65e0ff);
     -webkit-background-clip: text;
     -webkit-text-fill-color: transparent;
     font-weight: 600;
     }

     
     
   
     .hov:hover{
      font-size: 135%;
      background: -webkit-linear-gradient( #dfdcdc, #0bfa93);
     -webkit-background-clip: text;
     -webkit-text-fill-color: transparent;
     font-weight: 600;
   }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .navbar {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
   
    
    .logo-image {
      margin: 0 auto;
      max-width: 140%;
      max-height: 140%;
    }
    
    .size {
      font-size: 1.4rem;
    }

    .hov {
      font-size: 125%;
      background: -webkit-linear-gradient( #efeded, #65e0ff);
     -webkit-background-clip: text;
     -webkit-text-fill-color: transparent;
     font-weight: 600;
     }
     
   
     .hov:hover{
      font-size: 125%;
      background: -webkit-linear-gradient( #dfdcdc, #0bfa93);
     -webkit-background-clip: text;
     -webkit-text-fill-color: transparent;
     font-weight: 600;
   }
  }
  @media only screen and (min-width: 551px) and (max-width: 767px){
    .navbar {
      font-size: auto;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    
    .logo-image {
      margin: 0 auto;
      max-width: 140%;
      max-height: 140%;
    }
    

    .size {
      font-size: 1rem;
    }

    .hov {
      font-size: 105%;
      background: -webkit-linear-gradient( #efeded, #65e0ff);
     -webkit-background-clip: text;
     -webkit-text-fill-color: transparent;
     font-weight: 600;
     }
     
   
     .hov:hover{
      font-size: 105%;
      background: -webkit-linear-gradient( #dfdcdc, #0bfa93);
     -webkit-background-clip: text;
     -webkit-text-fill-color: transparent;
     font-weight: 600;
   }
  }

  @media only screen and (max-width: 551px) {
    .navbar {
      font-size: 2.2vh;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    

    .logo-image {
      margin: 0 auto;
      max-width: 140%;
      max-height: 140%;
    }
    

    

    .hov {
      font-size: 95%;
      background: -webkit-linear-gradient( #efeded, #65e0ff);
     -webkit-background-clip: text;
     -webkit-text-fill-color: transparent;
     font-weight: 600;
     }
     
   
     .hov:hover{
      font-size: 95%;
      background: -webkit-linear-gradient( #dfdcdc, #0bfa93);
     -webkit-background-clip: text;
     -webkit-text-fill-color: transparent;
     font-weight: 600;
   }
  }
  
.under:hover::after {
  content: "";
  display: block;
  height: 0.1vh;
  width: 80%;
  margin: 0 auto; 
  background-color: #ffffff;
}