.masonry-container {
    column-count: 3;
  }
  
  .masonry-item {
    display: inline-block;
    width: 100%;
  }
  
  .hhov {
      transform: scale(1);
      transition: transform 0.4s ease-in-out;
    }
    
    .hhov:hover {
      transform: scale(0.97);
      transition: transform 0.4s ease-in-out;
      box-shadow: 0 15px 20px rgb(26, 28, 26);
    }
  
  
    .masonry-container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 30px;
      padding: 4%;
    }
    
    .masonry-item {
      display: inline-block;
      width: 100%;
    }
    
    @media (max-width: 768px) {
      .masonry-container {
        grid-template-columns: repeat(1, 1fr);
      }
    }
    