@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,800;0,900;1,800&display=swap");

.tilt-neon {
  font-family: "Tilt neon";
  font-weight: 400;
}

.masonry-container {
  column-count: 3;
}

.masonry-item {
  display: inline-block;
  width: 100%;
}

.my-custom-tab-list {
  display: flex;
  justify-content: space-around;
  padding: 10px;
}

.my-custom-tab {
  font-size: 16px;
  cursor: pointer;
  padding: 10px 20px;
  border: 2px solid #fff;
  border-radius: 10px;
  background-color: #3e3f45;
  font-weight: bold;
  animation: pulse-glow 3s infinite;
  transition: box-shadow 0.3s ease-in-out, border-color 0.3s ease-in-out;
}

@media (min-width: 768px) {
  .react-tabs__tab-panel {
    padding: 20px;
    border: 2px solid #fff;
    border-radius: 10px;
  }
}

.my-custom-tab.react-tabs__tab--selected {
  background-color: #fff;
}

.react-tabs__tab-panel {
  padding: 0px;
}

.hhov {
  transform: scale(1);
  transition: transform 0.4s ease-in-out;
}

.hhov:hover {
  transform: scale(0.97);
  transition: transform 0.4s ease-in-out;
  box-shadow: 0 15px 20px rgb(26, 28, 26);
}
.masonry-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  padding: 4%;
}

.masonry-item {
  display: inline-block;
  width: 100%;
}

@media (max-width: 768px) {
  .masonry-container {
    grid-template-columns: repeat(1, 1fr);
  }
  img {
    width: 80%;
  }
}

.card-title {
  text-align: center;
  color: rgb(255, 255, 255);
  font-family: "Montserrat";
  margin-top: 5%;
  font-weight: 600;
}

@media (max-width: 768px) {
  .eventpic {
    width: 100%;
    margin-top: 10%;
    margin-bottom: 10%;
  }
  .event-button {
    font-size: 1.7vh;
  }

  .event-button {
    font-size: 2vh;
    background-image: linear-gradient(to left, #a977dc, #8496e6);
    border: 3px solid white;
  }

  .event-button:hover {
    font-size: 2vh;
    background-image: linear-gradient(to left, #2d1b3f, #404b7c);
    border: 3px solid blue;
    color: white;
  }
}

@media (min-width: 769px) {
  .eventpic {
    width: 80%;
    margin-top: 10%;
    margin-bottom: 10%;
  }

  .event-button {
    font-size: 2.7vh;
    background-image: linear-gradient(to left, #a977dc, #8496e6);
    border: 3px solid white;
  }

  .event-button:hover {
    font-size: 2.7vh;
    background-image: linear-gradient(to left, #2d1b3f, #404b7c);
    border: 3px solid blue;
    color: white;
  }
}

.card-main {
  position: relative;
  color: rgb(255, 255, 255);
  font-size: 5vh;
  width: 90%;
  height: 70%;
}

@media (max-width: 768px) {
  .card-title {
    color: rgb(255, 255, 255);
    font-size: 3vh;
  }

  .card-text {
    font-size: 2.5vh;
  }

  .card-main {
    position: relative;
    color: rgb(255, 255, 255);
    font-size: 5vh;
    margin: 0 auto;
    width: 90%;
    height: 60%;
  }
}

.card-body {
  font-size: 4vh;
}

.card {
  transform: scale(1);
  transition: transform 0.3s ease-in-out;
  border: 2px solid white;
  margin-bottom: 4%;
}

.card:hover {
  transform: scale(1.013);
  transition: transform 0.3s ease-in-out;
  border: 2px solid rgb(2, 225, 255);
}

.card-body {
  position: relative;
  color: rgb(255, 255, 255);
  font-size: 4vh;
}

/* Basic reset for margin and padding */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main-container {
  padding: 20px;
}

.event-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.event-item {
  display: flex;
  text-align: justify;
  width: 90%;
  margin-bottom: 2%;
}

.image-container {
  flex: 1;
  max-width: 70%;
}

.event-image {
  width: 80%;
  height: auto;
  max-height: 80%;
  object-fit: cover;
  transform: scale(1);
  transition: 0.2s transform ease-in-out;
  margin-top: 10%;
}

.event-image:hover {
  width: 80%;
  height: auto;
  max-height: 80%;
  object-fit: cover;
  transform: scale(1.02);
  transition: 0.2s transform ease-in-out;
  margin-top: 10%;
}

.event-details {
  flex: 1;
}

.event-title {
  font-size: 24px;
  margin-bottom: 10px;
}

.event-description {
  font-size: 16px;
  margin-bottom: 10px;
}

.event-fields {
  margin-bottom: 10px;
}

.event-fields p {
  font-size: 14px;
  margin-bottom: 5px;
}

.register-link {
  display: inline-block;
  font-size: 16px;
  padding: 10px 20px;
  background-color: #7e57c2;
  color: white;
  border: none;
  border-radius: 5px;
  text-decoration: none;
}

.register-link:hover {
  background-color: #6a1b9a;
}

@media (max-width: 768px) {
  .eventpic {
    width: 100%;
    margin-top: 10%;
    margin-bottom: 10%;
  }
  .event-button {
    font-size: 1.7vh;
  }

  .event-button {
    font-size: 2vh;
    background-image: linear-gradient(to left, #a977dc, #8496e6);
    border: 3px solid white;
    padding: 4%;
  }

  .event-button:hover {
    font-size: 2vh;
    background-image: linear-gradient(to left, #2d1b3f, #404b7c);
    border: 3px solid blue;
    padding: 4%;
    color: white;
  }

  .main-container {
    padding: 10px;
    margin-bottom: 2%;
  }

  .event-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-bottom: 2%;
  }

  .event-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: justify;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 2%;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }

  .event-image {
    min-width: 120%;
    margin-bottom: 10%;
  }

  .event-details {
    width: 100%;
  }

  .event-title {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .event-fields {
    font-size: 14px;
    margin-bottom: 5%;
  }

  .main-titles {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5%;
  }
}

@media (min-width: 769px) {
  .eventpic {
    width: 80%;
    margin-top: 10%;
    margin-bottom: 10%;
  }

  .event-button {
    font-size: 2.7vh;
    background-image: linear-gradient(to left, #a977dc, #8496e6);
    border: 3px solid white;
    padding: 1.2%;
  }

  .event-button:hover {
    font-size: 2.7vh;
    background-image: linear-gradient(to left, #2d1b3f, #404b7c);
    border: 3px solid blue;
    padding: 1.2%;
  }
}

.main-titles {
  font-size: 4.5vh;
  background: -webkit-linear-gradient(#dfdcdc, #0bfa93);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.main-titless {
  font-size: 5vh;
  background: -webkit-linear-gradient(#ffffff, #ed901e);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.content {
  font-size: 2.5vh;
  color: rgb(0, 0, 0);
}

@keyframes pulse-glow {
  0% {
    box-shadow: 0 0 20px rgba(3, 3, 3, 0.5);
    border-color: aqua;
  }
  25% {
    box-shadow: 0 0 30px rgba(17, 17, 18, 0.7);
    border-color: rgb(102, 38, 38);
  }
  50% {
    box-shadow: 0 0 30px rgba(17, 17, 18, 0.7);
    border-color: orange;
  }
  75% {
    box-shadow: 0 0 30px rgba(17, 17, 18, 0.7);
    border-color: greenyellow;
  }
  100% {
    box-shadow: 0 0 20px rgba(9, 9, 9, 0.5);
    border-color: orangered;
  }
}
