.container10 {
  max-width: 600px;
  margin: 0 auto;
  padding: 30px;
  background-color: #fff;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.6);
}

.payment-title {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.section-title {
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #333;
}

select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  color: #000000;
}

.discount-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  color: #333;
}

.discount-btn {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  width: 50%;
  border-radius: 5px;
  cursor: pointer;
  white-space: nowrap;
}

.discount-btn:hover {
  background-color: #0056b3;
}

.total-price {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  margin-top: 10px;
}

.payment-btn {
  background-color: #007bff;
  color: #fff;
  width: 50%;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  white-space: nowrap;
}

.payment-btn:hover {
  background-color: #0056b3;
}

.loader-container {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  vertical-align: middle;
}

.loader {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.footer {
  background-color: #007bff;
  color: #fff;
  padding: 20px 0;
  text-align: center;
}

@media (max-width: 768px) {
  .container10 {
    max-width: 90%;
  }
}

.dot-loader {
  display: inline-block;
  margin-left: 8px;
}

.dot {
  display: inline-block;
  width: 6px;
  height: 6px;
  background-color: #fff;
  border-radius: 50%;
  margin: 0 4px;
  animation: dot-bounce 1.5s infinite;
}

@keyframes dot-bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-8px);
  }
}
