$color_governor_bay_approx: rgba(57,49,175,1);
$color_bright_turquoise_approx: rgb(5, 36, 94);
$white: #fff;
$color_alabaster_approx: #95a4a7;
$color_ship_gray_approx: #383d41;
$color_science_blue_approx: #0062cc;
$color_abbey_approx: #000000;

.container-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .content-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
  }


.user-registration{
    color: aliceblue;
    background: linear-gradient(to right, #b87777, #a8c2de) ;
    margin-bottom: 5%;
    flex-grow: 1;
}

@media(max-width: 500px){
    .register {
        background: linear-gradient(to right, $color_governor_bay_approx 0, $color_bright_turquoise_approx 100%);
        margin-top: 5%;
        padding: 3%;
        overflow: hidden;
}
}

.register {
    color: aliceblue;
  background: linear-gradient(to right, $color_governor_bay_approx 0, $color_bright_turquoise_approx 100%);
  margin-top: 1%;
  margin-bottom: 5%;
  margin-left: 6%;
  padding: 3%;
  overflow: hidden;
  
  .register-form {
    padding: 10%;
    margin-top: 15%;
    
    @media (max-width: 991px) {
      margin-top: 16%;
    }
    
    @media (max-width: 667px) {
      margin-top: 20%;
    }
  }
  
  .nav-tabs {
    margin-top: 3%;
    border: none;
    background: $color_science_blue_approx;
    border-radius: 1.5rem;
    width: 28%;
    float: right;
    
    @media (max-width: 991px) {
      width: 33%;
      margin-top: 8%;
    }
    
    .nav-link {
      padding: 2%;
      height: 34px;
      font-weight: 600;
      color: $white;
      border-top-right-radius: 1.5rem;
      border-bottom-right-radius: 1.5rem;
      
      &:hover {
        border: none;
      }
      
      &.active {
        width: 100px;
        color: $color_science_blue_approx;
        border: 2px solid $color_science_blue_approx;
        border-top-left-radius: 1.5rem;
        border-bottom-left-radius: 1.5rem;
      }
    }
  }
}

.register-left {
  text-align: center;
  color: $white;
  margin-top: 4%;
  
  input {
    border: none;
    border-radius: 1.5rem;
    padding: 2%;
    width: 60%;
    background: -webkit-linear-gradient( #3972b0,#000f1f);
    border: 2px solid #c300ff;
    font-weight: bold;
    color: aliceblue;
    margin-top: 30%;
    margin-bottom: 3%;
    cursor: pointer;
  }

    
  input:hover {
    border: none;
    border-radius: 1.5rem;
    padding: 2%;
    width: 60%;
    background: -webkit-linear-gradient( #61a5ef,#083463);
    border: 2px solid #c300ff;
    font-weight: bold;
    color: aliceblue;
    margin-top: 30%;
    margin-bottom: 3%;
    cursor: pointer;
  }
  
  img {
    margin-top: 15%;
    margin-bottom: 5%;
    width: 25%;
    animation: mover 1s infinite alternate;
  }
  
  p {
    font-weight: lighter;
    padding: 12%;
    margin-top: -9%;
  }
}

.register-right {
  background: $color_alabaster_approx;
  border-top-left-radius: 10% 50%;
  border-bottom-left-radius: 10% 50%;
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
}

.btnRegister {
  float: right;
  margin-top: 10%;
  border: 2px solid #81caff;
  border-radius: 2.5rem;
  padding: 2%;
  background: linear-gradient(#3972b0,#022f5e);
  color: $white;
  font-weight: 600;
  width: 50%;
  cursor: pointer;
}
.btnRegister:hover {
    float: right;
    margin-top: 10%;
    border: 3px solid #e3dce5;
    border-radius: 2.5rem;
    padding: 2%;
    background: linear-gradient(#000000,#004187);
    color: $white;
    font-weight: 600;
    width: 50%;
    cursor: pointer;
  }

.register-heading {
  text-align: center;
  margin-top: 8%;
  margin-bottom: -15%;
  color: $color_abbey_approx;
  
  @media (max-width: 991px) {
    font-size: 1.5rem;
  }
}

.form-control{
    border: 2.5px solid #03545e;
}

.form-control:hover{
    border: 2.5px solid #0bb0c6;
    background:  linear-gradient( #bffef2,#b8cee7,);;
}