@media(min-width:550px){
.profile-button{
    font-size: 2.5vh;
}
}

@media(max-width:550px){
    .profile-button{
        font-size: 1.5vh;
    }
    }