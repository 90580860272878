@import url("https://fonts.googleapis.com/css2?family=Tilt+Neon&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,600;0,800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

section{
    
}

.pa{
    font-size: 110%;
    font-family: 'Montserrat';
    padding: 10% 0% 0% 18%;
    font-weight: 600;
}

.cycle{
    padding-left: 5%;
    padding-top: 10%;
    padding-bottom: 10%;
}

.cycle1{
    padding-left: -25%;
    padding-top: 10%;
}

.text {
    padding-left: 7%;
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 110%;
  }

  .text1 {
    padding-left: 3%;
    padding-top: -20%;
    font-size: 110%;
    font-family: 'Montserrat';
    font-weight: 600;
  }
  


.para{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  