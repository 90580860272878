
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;1,800&display=swap');

@media only screen and (max-width: 575px) {
    
    .text {
      font-size: 10px;
    }
  }

@media only screen and (min-width: 576px) and (max-width: 767px) {
   
    .text  {
      font-size: 12px;
    }
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    
    .text  {
      font-size: 15px;
    }
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    
    .text  {
      font-size: auto;
    }
  }
  
  @media only screen and (min-width: 1200px) {
    
    .text {
      font-size: auto;
    }
  }
  