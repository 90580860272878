@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,800;0,900;1,800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.bg{
  background-color: cadetblue;
}



.bg-image {
  opacity: 0.9; 
}

.icon-color{
  color: #04fa73;
}

.icon-color:hover{
  transition: 0.5s;
  transform: rotate(180deg);
}

.container {
  position: absolute;
  margin-top: 10%;
  font-family: 'Montserrat';
  font-weight: 600;
  left: 2%;
  font-size: 200%;
  color: rgb(255, 255, 255);
}

.cont{
  margin-top: 18%;
}

.container1 {
  position: absolute;
  top: 110%;
  left: 6%;
  font-size: 200%;
  color: black;
  font-family: 'Montserrat';
}  

@media only screen and (min-width: 1200px){
  

  .logo-image {
    margin: 0 auto;
    max-width: 80%;
    max-height: 80%;
  }

  .hov {
    font-size: 142%;
    background: -webkit-linear-gradient( #efeded, #65e0ff);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   font-weight: 600;
   }
   
 
   .hov:hover{
    font-size: 142%;
    background: -webkit-linear-gradient( #dfdcdc, #0bfa93);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   font-weight: 600;
 }
 .pbar{
  width: 140%;
  right: 3%;
  top: 100%;
 }
 
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  
  .logo-image {
    margin: 0 auto;
    max-width: 80%;
    max-height: 80%;
  }


  .hov {
      font-size: 115%;
      background: -webkit-linear-gradient( #efeded, #65e0ff);
     -webkit-background-clip: text;
     -webkit-text-fill-color: transparent;
     font-weight: 600;
     }
     .pbar{
      width: 170%;
      right: 1%;
      top: 90%;
     }
     
     
   
     .hov:hover{
      font-size: 115%;
      background: -webkit-linear-gradient( #dfdcdc, #0bfa93);
     -webkit-background-clip: text;
     -webkit-text-fill-color: transparent;
     font-weight: 600;
   }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .navbar {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
   
    
    .logo-image {
      margin: 0 auto;
      max-width: 140%;
      max-height: 140%;
    }
    
    .pbar{
      width: 180%;
      right: 0.1vh;
      top: 90%;
     }

    .size {
      font-size: 1.4rem;
    }

    .hov {
      font-size: 120%;
      background: -webkit-linear-gradient( #efeded, #65e0ff);
     -webkit-background-clip: text;
     -webkit-text-fill-color: transparent;
     font-weight: 600;
     }
     
   
     .hov:hover{
      font-size: 120%;
      background: -webkit-linear-gradient( #dfdcdc, #0bfa93);
     -webkit-background-clip: text;
     -webkit-text-fill-color: transparent;
     font-weight: 600;
   }
  }
  @media only screen and (min-width: 551px) and (max-width: 767px){
    .navbar {
      font-size: auto;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .pbar{
      width: 180%;
      right: 0.1vh;
      top: 90%;
      font-size:85% ;
     }
    
    .logo-image {
      margin: 0 auto;
      max-width: 140%;
      max-height: 140%;
    }
    

    .size {
      font-size: 1rem;
    }

    .hov {
      font-size: 105%;
      background: -webkit-linear-gradient( #efeded, #65e0ff);
     -webkit-background-clip: text;
     -webkit-text-fill-color: transparent;
     font-weight: 600;
     }
     
   
     .hov:hover{
      font-size: 105%;
      background: -webkit-linear-gradient( #dfdcdc, #0bfa93);
     -webkit-background-clip: text;
     -webkit-text-fill-color: transparent;
     font-weight: 600;
   }
  }

  @media only screen and (max-width: 551px) {
    .navbar {
      font-size: 1.7vh;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .pbar{
      width: 180%;
      right: 0.1vh;
      top: 90%;
      font-size:65% ;
     }

    .logo-image {
      margin: 0 auto;
      max-width: 140%;
      max-height: 140%;
    }
    

    

    .hov {
      font-size: 95%;
      background: -webkit-linear-gradient( #efeded, #65e0ff);
     -webkit-background-clip: text;
     -webkit-text-fill-color: transparent;
     font-weight: 600;
     }
     
   
     .hov:hover{
      font-size: 95%;
      background: -webkit-linear-gradient( #dfdcdc, #0bfa93);
     -webkit-background-clip: text;
     -webkit-text-fill-color: transparent;
     font-weight: 600;
   }
  }
  
.under:hover::after {
  content: "";
  display: block;
  height: 0.01vh;
  width: 80%;
  margin: 0 auto; 
  background-color: #ffffff;
}


  
  
